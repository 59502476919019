import API from "@utils/request";

const ARTICLE = "/index/";

/**
 * 资讯列表
 * @param {object} params 
 * @returns 
 */
export async function getArticleList(params) {
    try {
        return await API.post(ARTICLE + "articleList", params);
    } catch (error) {
        return error;
    }
}

/**
 * 编辑/回显
 * @param {object} params 
 * @param {string} type 
 * @returns 
 */
export async function articleUpdate(params, type = 'get') {
    try {
        if (type == 'get') {
            return await API.get(ARTICLE + "articleUpdate", { params });
        } else {
            return await API.post(ARTICLE + "articleUpdate", params);
        }

    } catch (error) {
        return error;
    }
}
/**
 * 字段修改
 * @param {object} params 
 * @returns 
 */
 export async function articleFieldUpdate(params) {
    try {
        return await API.post(ARTICLE + "articleFieldUpdate", params);
    } catch (error) {
        return error;
    }
}
/**
 * 新增
 * @param {object} params 
 * @returns 
 */
export async function articleAdd(params) {
    try {
        return await API.post(ARTICLE + "articleAdd", params);
    } catch (error) {
        return error;
    }
}
/**
 * 删除
 * @param {string} id 
 * @returns 
 */
export async function articleDelete(id) {
    try {
        return await API.post(ARTICLE + "articleDelete", {
            id: id,
        });
    } catch (error) {
        return error;
    }
}
/**
 * 关于我们列表
 * @param {object} params 
 * @returns 
 */
export async function getAboutList(params){
    try{
        return await API.post(ARTICLE + "aboutList",params);
    }catch(error){
        return error;
    }
}
/**
 * 关于我们编辑
 * @param {object} params 
 * @param {string} type 
 * @returns 
 */
export async function aboutUpdate(params,type='get'){
    try {
        if (type == 'get') {
            return await API.get(ARTICLE + "aboutUpdate", { params });
        } else {
            return await API.post(ARTICLE + "aboutUpdate", params);
        }

    } catch (error) {
        return error;
    }
}